
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import BANCard from "@/shared/components/cards/BANCard.vue";
import BlockCard from "@/shared/components/cards/BlockCard.vue";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";
import GhostListHeader from "@/shared/components/layouts/GhostListHeader.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import { useQuery } from "@vue/apollo-composable";
import { blockStatisticsGql } from "@/api/blocks/blocksStats";
import {
  BlockStatistics,
  BlockStatistics_blockStatistics_BlockStatistics,
} from "@/api/blocks/__generated__/BlockStatistics";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useGetBlockList } from "@/shared/composables/api/useGetBlockList";
import List from "@/shared/components/lists/List.vue";
import routeNames from "../router/routeNames";

export default defineComponent({
  setup() {
    const t = useI18n().t;

    const blockStatistics = useQuery<BlockStatistics>(blockStatisticsGql, {});
    const parsedGqlBlockStatistics = computed(() =>
      parseGqlResponse<BlockStatistics_blockStatistics_BlockStatistics>(
        "BlockStatistics",
        blockStatistics?.result?.value
      )
    );

    const {
      mappedDataForDisplay: blockListForDisplay,
      showMore,
      showMoreIsLoading,
      isLoading: isListLoading,
    } = useGetBlockList();

    const gotoBlockDetails = (hash) => ({
      name: routeNames.blockDetails,
      params: { hash },
    });

    const cardDetails = computed(() => {
      return [
        {
          cardTitle: t("total quantity"),
          cardAmount: parsedGqlBlockStatistics?.value?.data?.blocksCount,
        },
        {
          cardTitle: t("max per epoch"),
          cardAmount: parsedGqlBlockStatistics?.value?.data?.maxPerEpoch,
        },
        {
          cardTitle: t("avg per epoch"),
          cardAmount: parsedGqlBlockStatistics?.value?.data?.avePerEpoch,
        },
      ];
    });

    return {
      parsedGqlBlockStatistics,
      blockStatistics,
      t,
      cardDetails,
      blockListForDisplay: blockListForDisplay,
      showMoreIsLoading,
      showMore,
      gotoBlockDetails,
      isListLoading,
    };
  },
  components: {
    BANCard,
    BlockCard,
    GlobalSearchInput,
    GhostListHeader,
    PageHeaderTitle,
    List,
  },
});
