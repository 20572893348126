import gql from "graphql-tag";

export const blockStatisticsGql = gql`
  query BlockStatistics {
    blockStatistics {
      ... on BlockStatistics {
        blocksCount
        maxPerEpoch
        avePerEpoch
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
